import { Box } from "@mui/material";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import {
  DropzoneOptions,
  DropzoneRootProps,
  useDropzone,
} from "react-dropzone";
import styled from "styled-components";

const DropContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: inherit;
  width: inherit;
  background: #fbfbfb;
  border: 2px dashed
    ${(props: DropzoneRootProps) =>
      props.isDragActive ? "#42A5F5" : "#ADADAD"};
  border-radius: 6px;
  cursor: ${(props: DropzoneRootProps) =>
    props.disabled ? "not-allowed" : "pointer"};
  margin: 0 auto;
  opacity: ${(props: DropzoneRootProps) => (props.disabled ? 0.6 : 1)};
  transition: border 0.24s ease-in-out;
`;

const DropZone = ({ options }: { options: DropzoneOptions }) => {
  const disabled = options.disabled ? options.disabled : false;

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone(options);

  return (
    <DropContainer
      {...getRootProps({
        isDragActive,
        isDragAccept,
        isDragReject,
        disabled,
      })}
      aria-label="Drop/select files"
    >
      <input {...getInputProps()} />
      <CloudDownloadIcon
        sx={{ color: "primary.light", fontSize: "50px", lineHeight: 0 }}
      />
      <Box sx={{ mt: 1.5 }}>
        Drag and drop .vsdx files or
        <Box
          component="p"
          sx={{
            color: "primary.main",
            fontWeight: 500,
            textAlign: "center",
            mt: 1.25,
            "&:hover": {
              textDecoration: disabled ? "initial" : "underline",
            },
          }}
        >
          browse
        </Box>
      </Box>
    </DropContainer>
  );
};

export default DropZone;
