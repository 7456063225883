import { ConfirmProvider } from "material-ui-confirm";
import { SnackbarProvider } from "notistack";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ReportIcon from "@mui/icons-material/Report";
import SnackbarCloseButton from "./SnackbarCloseButton";
import MaterialSnackbar from "./MaterialSnackbar";
import App from "../App";

declare module "notistack" {
  interface VariantOverrides {
    materialInfo: {
      label?: string;
    },
    materialSuccess: {
      label?: string;
    },
    materialError: {
      label?: string;
    },
    materialWarning: {
      label?: string;
    },
  }
}

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 750,
      lg: 1150,
      xl: 1400,
    },
  },
});

const AppWrapper = () => {
  return (
    <ThemeProvider theme={theme}>
      <ConfirmProvider>
        <SnackbarProvider
          action={(snackbarId) => (
            <SnackbarCloseButton snackbarId={snackbarId} color="#FFF" />
          )}
          iconVariant={{
            error: <ReportIcon sx={{ mr: 1 }} />,
          }}
          Components={{
            materialInfo: MaterialSnackbar,
            materialSuccess: MaterialSnackbar,
            materialError: MaterialSnackbar,
            materialWarning: MaterialSnackbar,
          }}
        >
          <App />
        </SnackbarProvider>
      </ConfirmProvider>
    </ThemeProvider>
  );
};

export default AppWrapper;
