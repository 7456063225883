import { ReactNode } from "react";
import { Box } from "@mui/material";
import Footer from "./Footer";
import Header from "./Header";

const Layout = ({ children }: { children: ReactNode }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        height: "100%",
        margin: "auto",
      }}
    >
      <Header />
      <Box component="main" sx={{ flexGrow: 1, width: "95%", m: "0 auto" }}>
        {children}
      </Box>
      <Footer />
    </Box>
  );
};

export default Layout;
