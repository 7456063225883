import { useMemo } from "react";
import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import { Box, IconButton, Tooltip } from "@mui/material";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import DynamicFeedIcon from "@mui/icons-material/DynamicFeed";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import { ValidationError } from "../types/ValidationError";
import { Element } from "../types/Element";
import ValidationColumnDefinition from "../columndefs/ValidationColumnDefinition";

const ValidationTable = ({ data }: { data: ValidationError[] }) => {
  const validation_cols = useMemo<MRT_ColumnDef<ValidationError>[]>(
    () => ValidationColumnDefinition(),
    []
  );

  return (
    <MaterialReactTable
      columns={validation_cols}
      data={data}
      enableBottomToolbar={false}
      enableColumnActions={false}
      enableColumnDragging={false}
      enableColumnFilters={false}
      enableDensityToggle={false}
      enableFullScreenToggle={false}
      enableGlobalFilter={false}
      enableHiding={false}
      enableGrouping={data.length > 1}
      enableStickyHeader
      initialState={{
        expanded: true,
        sorting: [
          { id: "ids", desc: false },
          { id: "elements", desc: false },
          { id: "error", desc: false },
        ],
      }}
      filterFns={{
        idFilterFn: (row, id, value) => {
          const values = row.original.elements
            .map((el: Element) => el.shapeId)
            .flat();

          return values.some((id: number) => value.includes(id));
        },
        errorFilterFn: (row, id, value) =>
          value.includes(row.original.errorCode),
        elementFilterFn: (row, id, value) => {
          const values = row.original.elements
            .map((el: Element) => el.name)
            .flat();

          return values.some((name: string) => value.includes(name));
        },
      }}
      muiTableContainerProps={{
        sx: {
          maxHeight: 400,
          /* Chrome, Edge, and Safari */
          "&::-webkit-scrollbar": {
            height: 12,
            width: 12,
          },
          "&::-webkit-scrollbar-track": {
            background: "#FFF",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#A6A0A0",
            borderRadius: "10px",
            border: "3px solid #FFF",
            "&:hover": {
              backgroundColor: "#808080",
            },
          },
        },
      }}
      muiTableProps={{
        "aria-label": "Validation Errors",
      }}
      muiTableHeadCellProps={({ column }) => ({
        sx: {
          pt: 3,
          pb: 3,
          "& .Mui-TableHeadCell-Content-Wrapper": {
            mr: column.getIsSorted() ? 1 : 0,
          },
        },
      })}
      muiTableBodyProps={{
        sx: {
          "& tr:nth-of-type(odd)": {
            backgroundColor: "#F5F5F5",
          },
        },
      }}
      muiTableBodyRowProps={{ hover: false }}
      muiTableBodyCellProps={{
        sx: {
          border: 0,
        },
      }}
      muiTableHeadCellColumnActionsButtonProps={({ column }) => ({
        sx: {
          ml: column.getIsSorted() ? 0.5 : 0,
          opacity: column.getIsFiltered() ? 1 : 0.5,
          color: column.getIsFiltered() ? "#60C0CD" : "rgba(0, 0, 0, 0.54)",
        },
      })}
      renderToolbarInternalActions={({ table }) => {
        const columns = table.getAllColumns();

        return (
          <>
            <Tooltip
              title={table.options.localization.clearSort}
              placement="top"
              arrow
            >
              <Box>
                <IconButton
                  disabled={!columns.some((c) => c.getIsSorted())}
                  onClick={() => table.resetSorting(true)}
                >
                  <ClearAllIcon />
                </IconButton>
              </Box>
            </Tooltip>
            <Tooltip
              title="Reset filters"
              placement="top"
              sx={{ display: data.length > 1 ? "" : "none" }}
              arrow
            >
              <Box data-testid="reset">
                <IconButton
                  disabled={!columns.some((c) => c.getIsFiltered())}
                  onClick={() => table.resetColumnFilters()}
                >
                  <FilterListOffIcon />
                </IconButton>
              </Box>
            </Tooltip>
            <Tooltip
              title="Ungroup all"
              placement="top"
              sx={{ display: data.length > 1 ? "" : "none" }}
              arrow
            >
              <Box>
                <IconButton
                  disabled={!columns.some((c) => c.getIsGrouped())}
                  onClick={() => table.resetGrouping()}
                >
                  <DynamicFeedIcon />
                </IconButton>
              </Box>
            </Tooltip>
          </>
        );
      }}
    />
  );
};

export default ValidationTable;
