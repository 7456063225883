import {
  CheckboxAction,
  FILTER,
  SELECT,
  SELECT_ALL,
  SHOW_ALL,
} from "../actions/checkboxAction";
import { CheckboxOption } from "../types/CheckboxOption";

const checkboxReducer = (
  selection: CheckboxOption[],
  action: CheckboxAction
): CheckboxOption[] => {
  switch (action.type) {
    case FILTER: {
      return selection.map((s) => {
        return {
          ...s,
          visible:
            s.value === "all"
              ? true
              : s.value.toString().toLowerCase().includes(action.value.toLowerCase()),
        };
      });
    }
    case SELECT: {
      return selection.map((s) => {
        return s.value === action.value
          ? { ...s, selected: action.selected }
          : s;
      });
    }
    case SELECT_ALL: {
      return selection.map((s) => {
        return { ...s, selected: s.visible ? action.selected : s.selected };
      });
    }
    case SHOW_ALL: {
      return selection.map((s) => {
        return {
          ...s,
          visible: true,
        };
      });
    }
  }
};

export default checkboxReducer;
