import MaterialReactTable, { MRT_ColumnDef } from "material-react-table";
import { useMemo } from "react";
import { Error } from "../types/Error";

const columnDef: MRT_ColumnDef<Error>[] = [
  {
    accessorKey: "message",
    header: "Error",
  },
];

const ErrorTable = ({
  data
}: {
  data: Error[]
}) => {
  const columns = useMemo<MRT_ColumnDef<Error>[]>(
    () => columnDef,
    []
  );

  return (
    <MaterialReactTable
      columns={columns}
      data={data}
      enableColumnActions={false}
      enableColumnFilters={false}
      enablePagination={false}
      enableBottomToolbar={false}
      enableTopToolbar={false}
      muiTablePaperProps={{
        elevation: 0,
      }}
      muiTableProps={{
        "aria-label": "Errors"
      }}
      muiTableBodyRowProps={{ hover: false }}
      muiTableBodyCellProps={{
        sx: {
          border: 0,
        },
      }}
    />
  );
};

export default ErrorTable;
