import { ChangeEvent, CSSProperties } from "react";
import Checkbox from "@mui/material/Checkbox";
import { CheckboxOption } from "../types/CheckboxOption";
import MenuItem from "@mui/material/MenuItem";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import SimpleBar from "simplebar-react";
import { useTheme } from "@mui/material/styles";

const CheckBoxes = ({
  selection,
  style,
  onChange,
}: {
  selection: CheckboxOption[];
  style?: CSSProperties;
  onChange?: (
    e: ChangeEvent<HTMLInputElement>,
    checkbox: CheckboxOption
  ) => void;
}) => {
  const theme = useTheme();

  const indeterminate =
    selection.some((s) => s.selected) && !selection.every((s) => s.selected);

  const all_selected = selection
    .filter((s) => s.value !== "all")
    .every((s) => s.selected);

  const checkboxes = selection
    .filter((s) => s.visible)
    .map((s, i) => {
      if (s.value === "all") {
        return (
          <MenuItem
            key={i + 1}
            dense={false}
            value={s.value}
            sx={{
              display:
                selection.filter((s) => s.value !== "all" && s.visible)
                  .length === 0
                  ? "none"
                  : "",
              padding: "2px 16px",
              "&:hover": {
                backgroundColor: "#FFF",
              },
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <FormGroup>
              <FormControlLabel
                classes={{
                  label: "label-sm",
                }}
                control={
                  <Checkbox
                    checked={all_selected}
                    indeterminate={indeterminate}
                    size="small"
                    sx={{
                      color: style?.color
                        ? style.color
                        : theme.palette.primary.light,
                      "&.MuiCheckbox-indeterminate": {
                        color: "#A3A3A3 !important",
                      },
                      "&.Mui-checked": {
                        color: style?.color
                          ? style.color
                          : theme.palette.primary.light,
                      },
                    }}
                    onChange={(e) =>
                      typeof onChange === "function"
                        ? onChange(e, s)
                        : console.log(e.target)
                    }
                  />
                }
                label={s.label}
              />
            </FormGroup>
          </MenuItem>
        );
      }

      return (
        <MenuItem
          key={i + 1}
          value={s.value}
          sx={{
            padding: "2px 16px",
            "&:hover": {
              backgroundColor: "#FFF",
            },
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <FormGroup>
            <FormControlLabel
              classes={{
                label: "label-sm",
              }}
              control={
                <Checkbox
                  checked={s.selected}
                  size="small"
                  sx={{
                    color: style?.color
                      ? style.color
                      : theme.palette.primary.light,
                    "&.Mui-checked": {
                      color: style?.color
                        ? style.color
                        : theme.palette.primary.light,
                    },
                  }}
                  onClick={(e) => e.stopPropagation()}
                  onChange={(e) =>
                    typeof onChange === "function"
                      ? onChange(e, s)
                      : console.log(e.target)
                  }
                />
              }
              label={s.label}
            />
          </FormGroup>
        </MenuItem>
      );
    });

  return (
    <SimpleBar
      style={{
        maxHeight: style?.height ? style?.height : 180,
        width: style?.width ? style?.width : "100%",
      }}
    >
      {checkboxes}
    </SimpleBar>
  );
};

export default CheckBoxes;
