import { ChangeEvent, CSSProperties, useReducer } from "react";
import { Badge, Button } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import { CheckboxOption } from "../types/CheckboxOption";
import checkboxReducer from "../reducers/checkboxReducer";
import { CheckboxAction, SELECT, SELECT_ALL } from "../actions/checkboxAction";
import CheckBoxes from "./CheckBoxes";
import ActionMenu from "./ActionsMenu";

const FilterMenu = ({
  filter,
  options,
  setFilter,
  style,
}: {
  filter: string;
  options: CheckboxOption[];
  setFilter: Function;
  style?: CSSProperties;
}) => {
  const [selection, checkboxDispatch] = useReducer(checkboxReducer, options);

  const selected = selection.filter(
    (s) => s.value !== "all" && s.selected
  ).length;

  const onChange = (
    e: ChangeEvent<HTMLInputElement>,
    checkbox: CheckboxOption
  ) => {
    const selected =
      e.target.getAttribute("data-indeterminate") === "true" &&
      selection.filter((s) => s.visible && s.selected).length <
        selection.filter((s) => s.visible).length
        ? true
        : !checkbox.selected;

    let action: CheckboxAction;

    if (checkbox.value === "all") {
      action = {
        type: SELECT_ALL,
        selected: selected,
      };
    } else {
      action = {
        type: SELECT,
        value: checkbox.value,
        selected: selected,
      };
    }

    checkboxDispatch(action);

    const updated = checkboxReducer(selection, action).slice(1);

    if (updated.every((s) => s.selected)) {
      checkboxDispatch({
        type: SELECT_ALL,
        selected: true,
      });
    } else if (updated.every((s) => !s.selected)) {
      checkboxDispatch({
        type: SELECT_ALL,
        selected: false,
      });
    }

    setFilter(
      filter,
      updated
        .filter((s) => s.selected)
        .map((s) => s.value)
        .flat()
    );
  };

  const button = (
    <Button
      variant="text"
      startIcon={
        <>
          {selected < selection.length - 1 ? (
            <Badge badgeContent={selected} color="info">
              <FilterListIcon fontSize="small" />
            </Badge>
          ) : (
            <FilterListIcon fontSize="small" />
          )}
        </>
      }
      sx={{
        color: style?.color ? style.color : "primary.main",
      }}
    >
      Filter
    </Button>
  );

  return (
    <ActionMenu
      button={button}
      items={[
        <CheckBoxes selection={selection} style={style} onChange={onChange} />,
      ]}
      label={`Filter by ${filter}`}
    />
  );
};

export default FilterMenu;
