import { useReducer, useRef, useState } from "react";
import { Box, Button } from "@mui/material";
import { format } from "date-fns";
import Layout from "./components/Layout";
import AlertBanner from "./components/AlertBanner";
import DropZone from "./components/DropZone";
import UploadedFiles from "./components/UploadedFiles";
import { convertTree } from "./api/treeConverter";
import { Status } from "./enums/Status";
import fileDetailsReducer from "./reducers/fileDetailsReducer";
import {
  RESET,
  REMOVE_FILE_DETAIL,
  UPDATE_FILE_DETAIL_RESPONSE,
  ADD_FILE_DETAIL,
} from "./actions/fileDetailsAction";
import { createFile, saveFile, updateFile } from "./services/file";
import { MRT_TableInstance } from "material-react-table";
import { FileDetails } from "./types/FileDetails";
import { ValidationError } from "./types/ValidationError";
import { useConfirm } from "material-ui-confirm";
import { stringify, ColumnOption } from "csv-stringify/browser/esm/sync";
import JSZip from "jszip";
import { SnackbarKey, useSnackbar } from "notistack";
import { FileRejection } from "react-dropzone";
import { filter, flatten, groupBy, uniq } from "lodash";
import "./App.css";
import "simplebar-react/dist/simplebar.min.css";

function App() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [fileDetails, fileDetailsDispatch] = useReducer(fileDetailsReducer, []);
  const [converting, setConverting] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const [visible, setVisible] = useState(false);
  const [invalid, setInvalid] = useState([] as FileRejection[]);

  const tableRef = useRef<MRT_TableInstance<FileDetails>>(null);

  const confirm = useConfirm();
  const zip = new JSZip();

  const onConvertClick = () => {
    closeSnackbar();

    setVisible(false);
    setTimeout(() => setInvalid([]), 1000);

    setConverting(true);

    tableRef.current?.resetColumnFilters();
    tableRef.current?.getColumn("mrt-row-expand").toggleVisibility(false);
    tableRef.current?.toggleAllRowsExpanded(false);

    fileDetails.forEach((f) => {
      updateFile(fileDetailsDispatch, f, Status.Processing, {
        server: [],
        validation: [],
      });

      fileDetailsDispatch({
        type: UPDATE_FILE_DETAIL_RESPONSE,
        id: f.id,
        file: undefined,
      });
    });

    const promises = fileDetails.map((f) =>
      convertTree(f)
        .then((response) => {
          const status =
            response.errors.server.length > 0
              ? Status.Error
              : response.errors.validation.length > 0
              ? Status.Invalid
              : Status.Success;

          updateFile(fileDetailsDispatch, f, status, response.errors);

          if ([Status.Error, Status.Invalid].includes(status)) {
            tableRef.current
              ?.getColumn("mrt-row-expand")
              .toggleVisibility(true);
          }

          return response;
        })
        .catch((error) => {
          console.error(error);

          updateFile(fileDetailsDispatch, f, Status.Error, {
            server: [error],
            validation: [],
          });

          tableRef.current?.getColumn("mrt-row-expand").toggleVisibility(true);

          return Promise.reject(error);
        })
    );

    Promise.allSettled(promises)
      .then((results) => {
        const rejected = results.filter(
          (r) => r.status === "rejected" || r.value.errors.server.length > 0
        ) as PromiseRejectedResult[];

        const fulfilled = results.filter(
          (r) => r.status === "fulfilled"
        ) as PromiseFulfilledResult<any>[];

        const errors = fulfilled.filter(
          (r) => r.value.errors.validation.length > 0
        );

        const successful = fulfilled.filter((r) =>
          Object.values(r.value.errors).every((e) => (e as []).length === 0)
        );

        if (rejected.length > 0) {
          const snackbarId: SnackbarKey = enqueueSnackbar(
            `An error occurred when converting ${
              rejected.length === results.length ? "the" : rejected.length
            } files.`,
            {
              variant: "materialError",
              SnackbarProps: {
                onClick: () => closeSnackbar(snackbarId),
              },
            }
          );
        }

        if (errors.length > 0) {
          const columns = [
            { key: "shapeIds", header: "Shape ID(s)" },
            { key: "elements", header: "Element(s)" },
            { key: "error", header: "Error" },
            { key: "errorMessage", header: "Error Message" },
          ] as ColumnOption[];

          errors.forEach((response) => {
            const fileDetail = fileDetails.find(
              (f) => f.file.name === response.value.fileName
            );

            if (fileDetail !== undefined) {
              const errors = response.value.errors.validation.map(
                (e: ValidationError) => {
                  const shapeIds = e.elements.map((e) => e.shapeId).join(", ");
                  const elements = e.elements
                    .map((e) => (e.name ? e.name : "N/A"))
                    .join(", ");

                  return {
                    shapeIds: shapeIds,
                    elements: elements,
                    error: e.error,
                    errorMessage: e.errorMessage ? e.errorMessage : "N/A",
                  };
                }
              );

              const csv = createFile(
                new Blob([
                  stringify(errors, {
                    header: true,
                    columns: columns,
                  }),
                ]),
                response.value.fileName.replace(".vsdx", ".csv"),
                "application/xml"
              );

              fileDetailsDispatch({
                type: UPDATE_FILE_DETAIL_RESPONSE,
                id: fileDetail.id,
                file: csv,
              });
            }
          });
        }

        successful.forEach((response) => {
          const fileDetail = fileDetails.find(
            (f) => f.file.name === response.value.fileName
          );

          if (fileDetail !== undefined) {
            const tree = createFile(
              new Blob([response.value.xml]),
              response.value.fileName.replace(".vsdx", ".xml"),
              "application/xml"
            );

            fileDetailsDispatch({
              type: UPDATE_FILE_DETAIL_RESPONSE,
              id: fileDetail.id,
              file: tree,
            });
          }
        });
      })
      .finally(() => {
        setConverting(false);
        setShowStatus(true);
      });
  };

  const onResetClick = () => {
    fileDetailsDispatch({ type: RESET });

    closeSnackbar();

    setShowStatus(false);
    setVisible(false);
    setTimeout(() => setInvalid([]), 1000);

    tableRef.current?.resetColumnFilters();
    tableRef.current?.getColumn("mrt-row-expand").toggleVisibility(false);
    tableRef.current?.toggleAllRowsExpanded(false);
  };

  const onFilterClick = (type: string, values: string[]) => {
    if (values.length === 0) {
      tableRef.current?.resetColumnFilters();
      tableRef.current?.getColumn("mrt-row-expand").toggleVisibility(true);
    } else {
      const visible = [Status.Invalid, Status.Error].some((s) =>
        values.includes(s)
      );

      tableRef.current?.setColumnFilters((prev) => [
        ...prev,
        { id: type, value: values },
      ]);

      tableRef.current?.getColumn("mrt-row-expand").toggleVisibility(visible);

      if (!visible) tableRef.current?.toggleAllRowsExpanded(false);
    }
  };

  const onRemoveClick = (id: number) =>
    fileDetailsDispatch({ type: REMOVE_FILE_DETAIL, id: id });

  const generateZip = () => {
    const invalid = fileDetails.filter((f) => f.status === Status.Invalid);
    const successful = fileDetails.filter((f) => f.status === Status.Success);

    if (invalid.length > 0) {
      const validation = zip.folder("validation") as JSZip;
      const errorCsvs = invalid.map((f) => f.response);

      errorCsvs.forEach((f) => {
        if (f !== undefined) validation.file(f.name, f);
      });
    }

    if (successful.length > 0) {
      const trees = zip.folder("trees") as JSZip;
      const convertedTrees = successful.map((f) => f.response);

      convertedTrees.forEach((f) => {
        if (f !== undefined) trees.file(f.name, f);
      });
    }

    return zip.generateAsync({ type: "blob" });
  };

  const onExportClick = () => {
    closeSnackbar();

    generateZip()
      .then((zip) => {
        const snackbarId: SnackbarKey = enqueueSnackbar(
          <Box component="div" color="#205723">
            Your results are ready.
            <Button
              variant="text"
              color="inherit"
              size="small"
              sx={{
                fontSize: 12.5,
                ml: 2,
              }}
              onClick={() => {
                const current = new Date();
                
                saveFile(zip, `vtc-${format(current, "MM-dd-yyyy")}-${current.getTime()}.zip`);
              }}
            >
              Download now
            </Button>
          </Box>,
          {
            variant: "materialSuccess",
            persist: true,
            label: "Your results are ready to download.",
            style: {
              padding: "1px 16px",
            },
            SnackbarProps: {
              onClick: () => closeSnackbar(snackbarId),
            },
          }
        );
      })
      .catch((error) => {
        console.error(error);

        const snackbarId: SnackbarKey = enqueueSnackbar(
          <Box component="div" color="#D32F2F">
            Export failed.
            <Button
              variant="text"
              size="small"
              sx={{
                fontSize: 12.5,
                ml: 2,
              }}
              onClick={onExportClick}
            >
              Retry
            </Button>
          </Box>,
          {
            variant: "materialError",
            persist: true,
            label: "An error occurred when exporting.",
            SnackbarProps: {
              onClick: () => closeSnackbar(snackbarId),
            },
            style: {
              padding: "1px 16px",
            },
          }
        );
      });
  };

  const onDropRejected = (rejected: FileRejection[]) => {
    setInvalid((prev) => prev.concat(rejected));
    setVisible(true);
  };

  const options = {
    accept: {
      "application/vnd.ms-visio.drawing": [".vsdx"],
    },
    disabled: converting,
    minSize: 1,
    onDropAccepted: (accepted: File[]) => {
      const selected = fileDetails.map((f) => f.file.name);
      const grouped = groupBy(accepted, "name");

      const unique = uniq(
        flatten(filter(grouped, (n) => n.length === 1))
      ).filter((f) => !selected.includes(f.name));
      const duplicate = accepted.filter((f) => !unique.includes(f));

      if (duplicate.length > 0) {
        const rejected: FileRejection[] = duplicate.map((f) => {
          return {
            file: f,
            errors: [
              {
                code: "duplicate-file",
                message: "File name must be unique.",
              },
            ],
          };
        });

        onDropRejected(rejected);
      }

      unique.forEach((file) => {
        fileDetailsDispatch({
          type: ADD_FILE_DETAIL,
          fileDetail: {
            id: -1, // placeholder that will be updated during creation
            file: file,
            status: Status.Success,
            errors: {
              server: [],
              validation: [],
            },
          },
        });
      });
    },
    onDropRejected: onDropRejected,
  };

  return (
    <div className="App">
      <Layout>
        <AlertBanner
          setInvalid={setInvalid}
          invalid={invalid}
          visible={visible}
          setVisible={setVisible}
        />
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              aspectRatio: {
                xs: "3/1",
                lg: "2/3",
              },
              height: {
                xs: "30vw",
                md: "25vw",
                lg: "80vh",
              },
              maxWidth: {
                lg: "25vw",
              },
            }}
          >
            <DropZone options={options} />
          </Box>
          <Box
            sx={{
              ml: {
                lg: "60px",
              },
              width: {
                lg: "60vw",
              },
            }}
          >
            <UploadedFiles
              fileDetails={fileDetails}
              showStatus={showStatus}
              onConvertClick={onConvertClick}
              onExportClick={onExportClick}
              onResetClick={() => {
                confirm({
                  description: "This will remove all selected files.",
                  confirmationButtonProps: { color: "error" },
                  confirmationText: "Reset",
                })
                  .then(onResetClick)
                  .catch(() => {});
              }}
              onRemoveClick={onRemoveClick}
              onFilterClick={onFilterClick}
              tableRef={tableRef}
              converting={converting}
            />
          </Box>
        </Box>
      </Layout>
    </div>
  );
}

export default App;
