import { Box } from "@mui/material";
import { FileDetails } from "../types/FileDetails";
import { StatusIcon } from "../types/StatusIcon";
import { Status } from "../enums/Status";

const StatusBar = ({
  data,
  statuses,
}: {
  data: FileDetails[];
  statuses: StatusIcon[];
}) => {
  const statusDetails = statuses
    .map((s) => {
      const count = data.filter((d) => d.status === s.status).length;

      return {
        status: s.status,
        icon: s.icon,
        count: count,
        hide: s.status === Status.Processing && count === 0,
      };
    })
    .sort((a, b) => b.status.valueOf().localeCompare(a.status.valueOf()));

  return (
    <Box sx={{ display: "flex" }} role="status" aria-label="Status Indicators">
      {statusDetails.map((s, i) => {
        return (
          <Box
            aria-label={`${s.count} ${s.status}`}
            key={s.status}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mr: s.hide || i === statusDetails.length - 1 ? 0 : 1.5,
            }}
          >
            {s.icon}
            <Box
              component="span"
              sx={{
                alignSelf: "flex-end",
                fontFamily: "Roboto,Helvetica,Arial,sans-serif",
                fontSize: ".9em",
                fontWeight: "bold",
                ml: 1,
              }}
            >
              {s.count}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default StatusBar;
