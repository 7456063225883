import { Alert, AlertTitle, IconButton, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ReportIcon from "@mui/icons-material/Report";
import FadeInOut from "./FadeInOut";
import { Dispatch, SetStateAction } from "react";
import { FileRejection } from "react-dropzone";
import SimpleBar from "simplebar-react";
import styled from "styled-components";

const NestedList = styled.ul`
  li {
    font-weight: bold;
  }
  ul {
    list-style-type: none;
    margin: 8px 0;

    li {
      font-weight: 400;
      text-indent: -12px;
    }
    li:before {
      content: "- ";
      text-indent: -12px;
    }
  }
`;

const AlertBanner = ({
  setInvalid,
  invalid,
  visible,
  setVisible,
}: {
  setInvalid: Dispatch<SetStateAction<FileRejection[]>>;
  invalid: FileRejection[];
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
}) => {
  return (
    <FadeInOut
      show={visible}
      duration={400}
      style={{ width: "100%", margin: "15px auto 40px auto" }}
    >
      <SimpleBar style={{ maxHeight: 200 }}>
        <Alert
          aria-label={`${invalid.length} Invalid File{invalid.length > 1 ? "s" : ""} Dropped`}
          action={
            <Tooltip title="Close" placement="top">
              <IconButton
                size="small"
                onClick={() => {
                  setVisible(false);
                  setTimeout(() => setInvalid([]), 1000);
                }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          }
          icon={<ReportIcon fontSize="inherit" />}
          severity="error"
        >
          <AlertTitle sx={{ fontWeight: 600 }}>
            {invalid.length} Invalid File{invalid.length > 1 ? "s" : ""} Dropped
          </AlertTitle>
          <NestedList>
            {invalid.map((f, i) => (
              <li key={`f${i}`}>
                {f.file.name}
                <ul>
                  {f.errors.map((e, i) => (
                    <li key={`e${i}`}>
                      {e.message}
                      {e.message.endsWith(".") ? "" : "."}
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </NestedList>
        </Alert>
      </SimpleBar>
    </FadeInOut>
  );
};

export default AlertBanner;
