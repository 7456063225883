import { Box, Fab, useTheme } from "@mui/material";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";

const Header = () => {
  const theme = useTheme();

  return (
    <Box
      component="header"
      aria-label="header"
      sx={{
        display: "flex",
        width: "100%",
        p: "5px 0",
        justifyContent: "left",
        backgroundColor: "#f1f7fb",
        mb: "25px",
      }}
    >
      <Box
        component="img"
        sx={{
          height: "10vh",
          minHeight: "60px",
          position: "relative",
          top: "50%",
          msTransform: "translateY(-50%)",
          transform: "translateY(-50%)",
          ml: {
            xs: "4%",
            md: "3%",
            lg: "2%",
          },
        }}
        src="/assets/logo.png"
        alt="PALogic Tree Converter"
      />
      <Fab
        aria-label="User Guide"
        component="a"
        href="/Tree Converter User Guide.pdf"
        target="_blank"
        sx={{
          position: "fixed",
          right: "40px",
          bottom: "15px",
          color: "white",
          backgroundColor: theme.palette.primary.light,
          "&&:hover": {
            backgroundColor: theme.palette.primary.main,
          },
        }}
      >
        <QuestionMarkIcon />
      </Fab>
    </Box>
  );
};

export default Header;
