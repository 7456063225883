import { RefObject, useMemo } from "react";
import MaterialReactTable, {
  MRT_ColumnDef,
  MRT_Row,
  MRT_TableInstance,
} from "material-react-table";
import { Box, Button, useTheme } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import RemoveButton from "./RemoveButton";
import { FileDetails } from "../types/FileDetails";
import { Status } from "../enums/Status";
import { StatusIcon } from "../enums/StatusIcon";
import StatusBar from "./StatusBar";
import SimpleBar from "simplebar-react";
import { capitalizeWords, unique } from "../services/utilities";
import FileColumnDefinition from "../columndefs/FileColumnDefinition";
import FilterMenu from "./FilterMenu";
import ErrorTable from "./ErrorTable";
import ValidationTable from "./ValidationTable";

const UploadedFiles = ({
  fileDetails,
  converting,
  showStatus,
  onConvertClick,
  onExportClick,
  onResetClick,
  onRemoveClick,
  onFilterClick,
  tableRef,
}: {
  fileDetails: FileDetails[];
  converting: boolean;
  showStatus: boolean;
  onConvertClick: () => void;
  onExportClick: () => void;
  onResetClick: () => void;
  onRemoveClick: (id: number) => void;
  onFilterClick: (type: string, values: string[]) => void;
  tableRef: RefObject<MRT_TableInstance<FileDetails>>;
}) => {
  const theme = useTheme();

  const statuses = unique(fileDetails.map((d) => d.status)).sort((a, b) =>
    b.valueOf().localeCompare(a.valueOf())
  );

  const file_cols = useMemo<MRT_ColumnDef<FileDetails>[]>(
    () =>
      FileColumnDefinition({
        color: theme.palette.primary.light,
        accentColor: "#3998BD",
      }),
    [theme]
  );

  const renderErrors = (row: MRT_Row<FileDetails>) => {
    if (row.original.status === Status.Success) {
      return null;
    }

    const errors = row.original.errors;

    const serverErrors = errors.server;
    const validationErrors = errors.validation;

    if (serverErrors.length > 0) {
      return <ErrorTable data={serverErrors} />;
    } else {
      return <ValidationTable data={validationErrors} />;
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        m: "0 auto",
        width: {
          xs: "90vw",
          md: "80vw",
          lg: "60vw",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: {
            xs: "center",
            md: "space-between",
          },
          mt: {
            xs: "32px",
            lg: 0,
          },
          width: "inherit",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <Button
            variant="text"
            disabled={converting}
            onClick={onResetClick}
            startIcon={<RestartAltIcon />}
            sx={{
              ml: 0.75,
            }}
          >
            Reset
          </Button>
          {fileDetails.length > 0 && (
            <Button
              disabled={converting}
              variant="text"
              onClick={onConvertClick}
              startIcon={<PlayArrowIcon />}
            >
              Convert
            </Button>
          )}
          {!converting && showStatus && statuses.length > 1 && (
            <Box>
              <FilterMenu
                options={[
                  {
                    label: "Select All",
                    value: "all",
                    selected: false,
                    visible: true,
                  },
                  ...statuses.map((s) => {
                    return {
                      label: capitalizeWords(s),
                      value: s.valueOf(),
                      selected: false,
                      visible: true,
                    };
                  }),
                ]}
                filter="status"
                setFilter={onFilterClick}
                style={{
                  left: 50,
                  width: 175,
                }}
              />
            </Box>
          )}
          {!converting &&
            showStatus &&
            fileDetails.some((f) => f.status !== Status.Error) && (
              <Button
                variant="text"
                onClick={onExportClick}
                startIcon={<SaveAltIcon />}
              >
                Export
              </Button>
            )}
        </Box>
        {!converting && showStatus && (
          <Box
            sx={{
              alignSelf: "center",
            }}
          >
            <StatusBar
              data={fileDetails}
              statuses={statuses.map((s) => {
                if (s === Status.Processing) {
                  return {
                    status: s,
                    icon: StatusIcon.Processing,
                  };
                } else if (s === Status.Success) {
                  return {
                    status: s,
                    icon: StatusIcon.Success,
                  };
                } else if (s === Status.Invalid) {
                  return {
                    status: s,
                    icon: StatusIcon.Invalid,
                  };
                } else {
                  return {
                    status: s,
                    icon: StatusIcon.Error,
                  };
                }
              })}
            />
          </Box>
        )}
      </Box>
      <Box
        sx={{
          width: {
            lg: "60vw",
          },
        }}
      >
        <SimpleBar style={{ maxHeight: "80vh" }}>
          <MaterialReactTable
            columns={file_cols}
            data={fileDetails}
            tableInstanceRef={tableRef}
            positionActionsColumn="last"
            initialState={{
              columnVisibility: { "mrt-row-expand": false },
              density: "compact",
              expanded: { expanded: false },
            }}
            enableColumnActions={false}
            enableColumnFilters={false}
            enablePagination={false}
            enableSorting={false}
            enableTableHead={false}
            enableToolbarInternalActions={false}
            enableTopToolbar={false}
            enableBottomToolbar={false}
            enableEditing
            displayColumnDefOptions={{
              "mrt-row-expand": {
                enableHiding: true,
                minSize: 10,
                maxSize: 20,
                size: 10,
              },
              "mrt-row-actions": {
                minSize: 18,
                maxSize: 18,
                size: 18,
              },
            }}
            localization={{
              noRecordsToDisplay: "No files selected",
            }}
            muiExpandButtonProps={({ row }) => ({
              sx: {
                display: [Status.Error, Status.Invalid].includes(
                  row.original.status
                )
                  ? "inline-flex"
                  : "none",
              },
            })}
            muiTableContainerProps={{
              sx: {
                mt: 2,
              },
            }}
            muiTablePaperProps={{
              elevation: 0,
              sx: {
                border: 0,
              },
            }}
            muiTableProps={{
              "aria-label": "File Selection",
              sx: {
                tableLayout: "fixed",
              },
            }}
            muiTableBodyProps={{
              sx: {
                "& tr.Mui-TableBodyCell-DetailPanel": {
                  justifyContent: "center",
                },
                "& p.MuiTypography-root": {
                  m: "0 auto",
                },
              },
            }}
            muiTableBodyRowProps={{ hover: false }}
            muiTableBodyCellProps={{
              sx: {
                border: 0,
              },
            }}
            muiTableDetailPanelProps={{
              sx: {
                border: 0,
              },
            }}
            renderDetailPanel={({ row }) => renderErrors(row)}
            renderRowActions={({ row }) => (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <RemoveButton
                  id={row.original.id}
                  item={row.original.file.name}
                  onRemoveClick={onRemoveClick}
                  disabled={converting}
                />
              </Box>
            )}
          />
        </SimpleBar>
      </Box>
    </Box>
  );
};

export default UploadedFiles;
