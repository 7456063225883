import { useState } from "react";
import { Badge, Box, IconButton, Menu } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";

const ActionMenu = ({
  button,
  selected,
  items,
  label,
}: {
  button?: JSX.Element;
  selected?: number;
  items: JSX.Element[];
  label: string;
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const onOpen = (e: any) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const onClose = (e: any) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <>
      <Box
        onClick={(e) => (open ? onClose(e) : onOpen(e))}
        sx={{ alignSelf: "center" }}
      >
        {button ? (
          button
        ) : (
          <IconButton
            aria-label={selected ? `${selected} filters applied.` : label}
            size={selected ? "large" : "small"}
            sx={{
              color: selected ? "primary.main" : "initial",
              opacity: selected ? 1 : 0.5,
              transition: "opacity 150ms",
              "&:hover": {
                opacity: 1,
                background: "initial",
              },
            }}
          >
            {selected ? (
              <Badge badgeContent={selected} color="info">
                <FilterListIcon fontSize="small" />
              </Badge>
            ) : (
              <FilterListIcon fontSize="small" />
            )}
          </IconButton>
        )}
      </Box>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        aria-label={label}
        open={open}
        onClose={onClose}
        PaperProps={{
          elevation: 0,
          sx: {
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1,
          },
        }}
        transformOrigin={{ horizontal: "center", vertical: "top" }}
        variant="menu"
      >
        {items.map((item, i) => {
          return (
            <Box key={i} onClick={onClose}>
              {item}
            </Box>
          );
        })}
      </Menu>
    </>
  );
};

export default ActionMenu;
