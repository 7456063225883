import { ConverterResponse } from "../types/ConverterResponse";
import { FileDetails } from "../types/FileDetails";

const convertTree = (fileDetails: FileDetails) => {
  const data = new FormData();
  const file = fileDetails.file;

  data.append("file", file);

  const requestOptions = {
    method: "POST",
    body: data,
  };

  return fetch(
    `${
      process.env.REACT_APP_TREE_CONVERTER_API_BASE_URL
    }/api/v1/client/${process.env.REACT_APP_ENVIRONMENT?.toLowerCase()}/trees/convert`,
    requestOptions
  ).then(async (response) => {
    if (response.ok) {
      const data = await response.json();

      return {
        fileName: file.name,
        errors: {
          server: data.errors,
          validation: data.validationErrors,
        },
        xml: data.xml,
      } as ConverterResponse;
    }

    return Promise.reject(response);
  });
};

export { convertTree };
