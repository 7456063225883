import { ValidationError } from "../types/ValidationError";
import { MRT_ColumnDef } from "material-react-table";
import { Box, ListItemIcon, MenuItem, Tooltip } from "@mui/material";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import DynamicFeedIcon from "@mui/icons-material/DynamicFeed";
import HelpIcon from "@mui/icons-material/Help";
import SortIcon from "@mui/icons-material/Sort";
import ActionsMenu from "../components/ActionsMenu";
import CheckboxFilter from "../components/CheckboxFilter";
import { capitalizeWords, unique } from "../services/utilities";
import { MouseEventHandler } from "react";

const item = (
  item: string,
  icon: JSX.Element,
  disabled: boolean,
  onClick: MouseEventHandler<HTMLLIElement>,
  divider?: boolean
) => {
  return (
    <MenuItem
      divider={divider ? divider : false}
      disabled={disabled}
      onClick={onClick}
      sx={{
        py: "6px",
        my: 0,
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        {item}
      </Box>
    </MenuItem>
  );
};

const ValidationColumnDefinition = (): MRT_ColumnDef<ValidationError>[] => {
  return [
    {
      accessorFn: (row) => row.elements.map((e) => e.shapeId).join(", "),
      id: "ids",
      header: "Shape ID(s)",
      aggregationFn: "unique",
      filterFn: "idFilterFn",
      Header: ({ column, table }) => {
        const localization = table.options.localization;
        const header = column.columnDef.header;

        const clearAsc = localization.sortByColumnAsc?.replace(
          "{column}",
          header
        );

        const clearDesc = localization.sortByColumnDesc?.replace(
          "{column}",
          header
        );

        const group = localization[
          column.getIsGrouped() ? "ungroupByColumn" : "groupByColumn"
        ]?.replace("{column}", header);

        const shapeIds = table.options.data
          .map((e, i) => column.accessorFn?.call(this, e, i) as string)
          .map((s) => s.split(", ").map(Number))
          .flat()
          .sort((a, b) => a - b);

        const items = [
          item(
            localization.clearSort,
            <ClearAllIcon />,
            !column.getIsSorted(),
            () => column.clearSorting()
          ),
          item(
            clearAsc,
            <SortIcon style={{ transform: "rotate(180deg) scaleX(-1)" }} />,
            column.getIsSorted() === "asc",
            () => column.toggleSorting(false, true)
          ),
          item(
            clearDesc,
            <SortIcon />,
            column.getIsSorted() === "desc",
            () => column.toggleSorting(true, true),
            shapeIds.length > 1
          ),
        ];

        const filtered = table
          .getState()
          .columnFilters.filter((f) => f.id === column.id)
          .map((f) => f.value as number[])
          .pop();

        if (shapeIds.length > 1) {
          const options = unique(shapeIds).map((id) => {
            return {
              label: id,
              value: id,
              selected: filtered
                ? filtered.length === shapeIds.length || filtered.includes(id)
                : false,
              visible: true,
            };
          });

          options.unshift({
            label: "Select All",
            value: "all",
            selected: !filtered ? false : filtered.length === shapeIds.length,
            visible: true,
          });

          const onFilterClick = (id: string, values: number[]) => {
            if (values.length === 0 || values.length === options.length)
              table.setColumnFilters((prev) => prev.filter((p) => p.id !== id));
            else
              table.setColumnFilters((prev) => [
                ...prev,
                { id: id, value: values },
              ]);
          };

          items.push(
            item(
              group,
              <DynamicFeedIcon />,
              false,
              () => {
                column.toggleGrouping();
                table.setColumnOrder((prev: any) => [
                  "mrt-row-expand",
                  ...prev,
                ]);
              },
              true
            ),
            <CheckboxFilter
              enableSearch={true}
              filter={column.id}
              options={options}
              setFilter={onFilterClick}
            />
          );
        }

        const active = shapeIds.filter((s) => filtered?.includes(s));

        return (
          <Box sx={{ display: "flex" }}>
            <Box component="p">{column.columnDef.header}</Box>
            <ActionsMenu
              selected={
                active === undefined || active.length === shapeIds.length
                  ? 0
                  : active.length
              }
              items={items}
              label={"Filter, sort, and group by shape ID"}
            />
          </Box>
        );
      },
      AggregatedCell: ({ cell }) => (
        <span>{cell.getValue<string[]>().join(", ")}</span>
      ),
      size: 150,
    },
    {
      accessorFn: (row) =>
        unique(row.elements.map((e) => (e.name ? e.name : "N/A"))).join(", "),
      id: "elements",
      header: "Element(s)",
      aggregationFn: "unique",
      filterFn: "elementFilterFn",
      Header: ({ column, table }) => {
        const localization = table.options.localization;
        const header = column.columnDef.header;

        const clearAsc = localization.sortByColumnAsc?.replace(
          "{column}",
          header
        );

        const clearDesc = localization.sortByColumnDesc?.replace(
          "{column}",
          header
        );

        const group = localization[
          column.getIsGrouped() ? "ungroupByColumn" : "groupByColumn"
        ]?.replace("{column}", header);

        const elements = unique(
          table.options.data
            .map((e, i) => column.accessorFn?.call(this, e, i) as string[])
            .flat()
            .sort()
        );

        const items = [
          item(
            localization.clearSort,
            <ClearAllIcon />,
            !column.getIsSorted(),
            () => column.clearSorting()
          ),
          item(
            clearAsc,
            <SortIcon style={{ transform: "rotate(180deg) scaleX(-1)" }} />,
            column.getIsSorted() === "asc",
            () => column.toggleSorting(false, true)
          ),
          item(
            clearDesc,
            <SortIcon />,
            column.getIsSorted() === "desc",
            () => column.toggleSorting(true, true),
            elements.length > 1
          ),
        ];

        const filtered = table
          .getState()
          .columnFilters.filter((f) => f.id === column.id)
          .map((c) => c.value as string[])
          .map((c) => c.map((v) => (v === "" ? "N/A" : v)))
          .pop();

        if (elements.length > 1) {
          const options = elements.map((id) => {
            return {
              label: id,
              value: id,
              selected: filtered
                ? filtered.length === elements.length || filtered.includes(id)
                : false,
              visible: true,
            };
          });

          options.unshift({
            label: "Select All",
            value: "all",
            selected: !filtered ? false : filtered.length === elements.length,
            visible: true,
          });

          const onFilterClick = (id: string, values: string[]) => {
            if (values.length === 0 || values.length === options.length)
              table.setColumnFilters((prev) => prev.filter((p) => p.id !== id));
            else
              table.setColumnFilters((prev) => [
                ...prev,
                {
                  id: id,
                  value: values.map((val) => (val === "N/A" ? "" : val)),
                },
              ]);
          };

          items.push(
            item(
              group,
              <DynamicFeedIcon />,
              false,
              () => {
                column.toggleGrouping();
                table.setColumnOrder((prev: any) => [
                  "mrt-row-expand",
                  ...prev,
                ]);
              },
              true
            ),
            <CheckboxFilter
              enableSearch={true}
              filter={column.id}
              options={options}
              setFilter={onFilterClick}
            />
          );
        }

        const active = elements.filter((e) => filtered?.includes(e));

        return (
          <Box sx={{ display: "flex" }}>
            <Box component="p">{column.columnDef.header}</Box>
            <ActionsMenu
              selected={
                active === undefined || active.length === elements.length
                  ? 0
                  : active.length
              }
              items={items}
              label={"Filter, sort, and group by element"}
            />
          </Box>
        );
      },
      AggregatedCell: ({ cell }) => (
        <span>{cell.getValue<string[]>().join(", ")}</span>
      ),
    },
    {
      accessorKey: "error",
      header: "Error",
      aggregationFn: "unique",
      filterFn: "errorFilterFn",
      Header: ({ column, table }) => {
        const localization = table.options.localization;
        const header = column.columnDef.header;

        const clearAsc = localization.sortByColumnAsc?.replace(
          "{column}",
          header
        );

        const clearDesc = localization.sortByColumnDesc?.replace(
          "{column}",
          header
        );

        const group = localization[
          column.getIsGrouped() ? "ungroupByColumn" : "groupByColumn"
        ]?.replace("{column}", header);

        const errors = unique(
          table.options.data.map((e) => e.errorCode)
        ).sort();

        const items = [
          item(
            localization.clearSort,
            <ClearAllIcon />,
            !column.getIsSorted(),
            () => column.clearSorting()
          ),
          item(
            clearAsc,
            <SortIcon style={{ transform: "rotate(180deg) scaleX(-1)" }} />,
            column.getIsSorted() === "asc",
            () => column.toggleSorting(false, true)
          ),
          item(
            clearDesc,
            <SortIcon />,
            column.getIsSorted() === "desc",
            () => column.toggleSorting(true, true),
            errors.length > 1
          ),
        ];

        const filtered = table
          .getState()
          .columnFilters.filter((f) => f.id === column.id)
          .map((f) => f.value as string[])
          .pop();

        const active = errors.filter((e) => filtered?.includes(e));

        if (errors.length > 1) {
          const options = errors.map((e) => {
            const label = capitalizeWords(e.replaceAll("_", " "));

            return {
              label: label,
              value: e,
              selected: filtered
                ? filtered.length === errors.length || filtered.includes(e)
                : false,
              visible: true,
            };
          });

          options.unshift({
            label: "Select All",
            value: "all",
            selected: !filtered ? false : filtered.length === errors.length,
            visible: true,
          });

          const onFilterClick = (id: string, values: string[]) => {
            if (values.length === 0 || values.length === options.length)
              table.setColumnFilters((prev) => prev.filter((p) => p.id !== id));
            else
              table.setColumnFilters((prev) => [
                ...prev,
                { id: id, value: values },
              ]);
          };

          items.push(
            item(
              group,
              <DynamicFeedIcon />,
              false,
              () => {
                column.toggleGrouping();
                table.setColumnOrder((prev: any) => [
                  "mrt-row-expand",
                  ...prev,
                ]);
              },
              true
            ),
            <CheckboxFilter
              enableSearch={true}
              filter={column.id}
              options={options}
              setFilter={onFilterClick}
            />
          );
        }

        return (
          <Box sx={{ display: "flex" }}>
            <Box component="p">{column.columnDef.header}</Box>
            <ActionsMenu
              selected={
                active === undefined || active.length === errors.length
                  ? 0
                  : active.length
              }
              items={items}
              label={"Filter, sort, and group by error"}
            />
          </Box>
        );
      },
      Cell: ({ cell }) => {
        const error = cell.getValue<string>();
        const desc = cell.row.original.errorMessage;

        return (
          <Box>
            {error}
            {desc ? (
              <Tooltip title={desc} placement="top" arrow>
                <HelpIcon
                  sx={{
                    color: "primary.light",
                    fontSize: "18px",
                    ml: "5px",
                    opacity: 0.75,
                    verticalAlign: "middle",
                  }}
                ></HelpIcon>
              </Tooltip>
            ) : null}
          </Box>
        );
      },
      AggregatedCell: ({ cell }) => (
        <span>{cell.getValue<string[]>().join(", ")}</span>
      ),
    },
  ];
};

export default ValidationColumnDefinition;
