import {
  Box,
  Tooltip,
} from "@mui/material";
import { FileDetails } from "../types/FileDetails";
import { MRT_ColumnDef } from "material-react-table";
import { Status } from "../enums/Status";
import { StatusIcon } from "../enums/StatusIcon";
import { CSSProperties } from "react";

const FileColumnDefinition = (
  style: CSSProperties
): MRT_ColumnDef<FileDetails>[] => {
  return [
    {
      accessorKey: "status",
      header: "",
      columnDefType: "display",
      filterFn: "arrIncludesSome",
      minSize: 10,
      maxSize: 20,
      size: 10,
      Cell: ({ cell, row }) => {
        const status = cell.getValue<string>();
        const errors = row.original.errors;

        if (status === Status.Processing) {
          return StatusIcon.Processing;
        } else if (status === Status.Success) {
          return StatusIcon.Success;
        } else if (status === Status.Invalid) {
          return (
            <Tooltip
              title={
                <Box
                  component="p"
                  sx={{ cursor: "pointer", margin: 0, textAlign: "center" }}
                >
                  {errors.validation.length} validation error
                  {errors.validation.length > 1 ? "s" : ""} detected.
                  <br />
                  <Box
                    component="span"
                    sx={{
                      color: style.color,
                      fontWeight: 600,
                      transition: "0.5s all ease",
                      "&:hover": {
                        color: style.accentColor,
                      },
                    }}
                    onClick={() => row.toggleExpanded(!row.getIsExpanded())}
                  >
                    {row.getIsExpanded() ? "Hide details" : "View details"}
                  </Box>
                </Box>
              }
              placement="top"
              arrow
            >
              {StatusIcon.Invalid}
            </Tooltip>
          );
        } else if (status === Status.Error) {
          return (
            <Tooltip
              title={
                <Box
                  component="p"
                  sx={{ cursor: "pointer", margin: 0, textAlign: "center" }}
                >
                  {errors.server.length > 1
                    ? `${errors.server.length} errors`
                    : "An error"} occurred during conversion.
                  <br />
                  <Box
                    component="span"
                    sx={{
                      color: style.color,
                      fontWeight: 600,
                      transition: "0.5s all ease",
                      "&:hover": {
                        color: style.accentColor,
                      },
                    }}
                    onClick={() => row.toggleExpanded(!row.getIsExpanded())}
                  >
                    {row.getIsExpanded() ? "Hide details" : "View details"}
                  </Box>
                </Box>
              }
              placement="top"
              arrow
            >
              {StatusIcon.Error}
            </Tooltip>
          );
        }

        console.error(`Invalid status: ${status}`);
      },
    },
    {
      accessorKey: "file.name",
      header: "",
      minSize: 200,
      maxSize: 350,
      Cell: ({ renderedCellValue }) => (
        <Tooltip title={renderedCellValue} placement="top-start" arrow>
          <Box
            style={{
              maxWidth: 250,
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {renderedCellValue}
          </Box>
        </Tooltip>
      ),
    },
    {
      accessorKey: "file.size",
      header: "",
      minSize: 50,
      maxSize: 50,
      Cell: ({ cell }) => (
        <Box component="span">
          {Math.round(cell.getValue<number>() / 1000)} KB
        </Box>
      ),
    },
  ];
};

export default FileColumnDefinition;
