import { CircularProgress } from "@mui/material";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import ReportOutlinedIcon from "@mui/icons-material/ReportOutlined";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";

export const StatusIcon = {
  Processing: (
    <CircularProgress
      key="Processing"
      size={20}
      sx={{ color: "#42A5F5", verticalAlign: "middle" }}
      thickness={4}
    />
  ),
  Success: (
    <CheckCircleOutlineRoundedIcon
      key="success"
      htmlColor="#009E0F"
      fontSize="small"
      sx={{ verticalAlign: "middle" }}
    />
  ),
  Invalid: (
    <WarningAmberRoundedIcon
      key="invalid"
      color="warning"
      fontSize="small"
      sx={{ verticalAlign: "middle" }}
    />
  ),
  Error: (
    <ReportOutlinedIcon
      key="error"
      color="error"
      fontSize="small"
      sx={{ verticalAlign: "middle" }}
    />
  ),
};
