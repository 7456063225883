import {
  IconButton,
  TextField,
  Tooltip,
  useTheme,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { CSSProperties, useState } from "react";

const SearchInput = ({
  style,
  onChange,
  onClear,
}: {
  style?: CSSProperties;
  onChange?: Function;
  onClear?: Function;
}) => {
  const theme = useTheme();
  const [search, setSearch] = useState("");

  return (
    <TextField
      autoComplete="off"
      name="search"
      placeholder="Search"
      value={search}
      inputProps={{
        "aria-label": "Search",
        style: style,
      }}
      InputProps={{
        endAdornment: (
          <Tooltip title={search ? "Clear" : "Search"} placement="top" arrow>
            <IconButton
              size="small"
              onClick={(e) => {
                setSearch("");

                typeof onClear === "function"
                  ? onClear(e)
                  : console.log(e.target);
              }}
            >
              <ClearIcon
                fontSize="small"
                sx={{ display: search ? "" : "none" }}
              />
              <SearchIcon
                fontSize="small"
                sx={{ display: search ? "none" : "" }}
              />
            </IconButton>
          </Tooltip>
        ),
      }}
      size="small"
      sx={{
        m: 1,
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: style?.color
              ? style.color
              : theme.palette.primary.light,
          },
          "&:hover fieldset": {
            borderColor: style?.color
              ? style.color
              : theme.palette.primary.light,
          },
          "&.Mui-focused fieldset": {
            borderColor: style?.color
              ? style.color
              : theme.palette.primary.light,
          },
        },
      }}
      onClick={(e) => e.stopPropagation()}
      onChange={(e) => {
        setSearch(e.target.value);
        typeof onChange === "function" ? onChange(e) : console.log(e.target);
      }}
      onKeyDown={(e) => e.stopPropagation()}
      fullWidth
    />
  );
};

export default SearchInput;
