import {
  ChangeEvent,
  CSSProperties,
  useReducer,
} from "react";
import { Box, MenuItem } from "@mui/material";
import { CheckboxOption } from "../types/CheckboxOption";
import CheckBoxes from "./CheckBoxes";
import SearchInput from "./SearchInput";
import checkboxReducer from "../reducers/checkboxReducer";
import {
  CheckboxAction,
  FILTER,
  SELECT,
  SELECT_ALL,
  SHOW_ALL,
} from "../actions/checkboxAction";

const CheckboxFilter = ({
  enableSearch,
  filter,
  options,
  setFilter,
  style,
}: {
  enableSearch?: boolean;
  filter: string;
  options: CheckboxOption[];
  setFilter: Function;
  style?: CSSProperties;
}) => {
  const [selection, checkboxDispatch] = useReducer(checkboxReducer, options);

  const onChange = (
    e: ChangeEvent<HTMLInputElement>,
    checkbox: CheckboxOption
  ) => {
    const selected =
      e.target.getAttribute("data-indeterminate") === "true" &&
      selection.filter((s) => s.visible && s.selected).length <
        selection.filter((s) => s.visible).length
        ? true
        : !checkbox.selected;

    let action: CheckboxAction;

    if (checkbox.value === "all") {
      action = {
        type: SELECT_ALL,
        selected: selected,
      };
    } else {
      action = {
        type: SELECT,
        value: checkbox.value,
        selected: selected,
      };
    }

    checkboxDispatch(action);

    const updated = checkboxReducer(selection, action).slice(1);

    if (updated.every((s) => s.selected)) {
      checkboxDispatch({
        type: SELECT_ALL,
        selected: true,
      });
    } else if (updated.every((s) => !s.selected)) {
      checkboxDispatch({
        type: SELECT_ALL,
        selected: false,
      });
    }

    setFilter(
      filter,
      updated
        .filter((s) => s.selected)
        .map((s) => s.value)
        .flat()
    );
  };

  return (
    <>
      {enableSearch && (
        <MenuItem
          sx={{
            background: "#FFF",
            padding: "14px 16px 2px 16px",
            position: "sticky",
            top: 0,
            zIndex: 10000,
            "&:hover": {
              backgroundColor: "initial",
            },
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <SearchInput
            style={{
              fontSize: ".9em",
              padding: "6px 16px",
            }}
            onChange={(
              e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => {
              checkboxDispatch({
                type: FILTER,
                value: e.target.value,
              });
            }}
            onClear={() => checkboxDispatch({ type: SHOW_ALL })}
          />
        </MenuItem>
      )}
      {enableSearch &&
        selection.filter((s) => s.value !== "all" && s.visible).length ===
          0 && (
          <MenuItem
            sx={{
              justifyContent: "center",
              pt: "12px",
              pb: "16px",
              "&:hover": {
                backgroundColor: "#FFF",
              },
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <Box className="label-sm">No results found.</Box>
          </MenuItem>
        )}
      <CheckBoxes selection={selection} style={style} onChange={onChange} />
    </>
  );
};

export default CheckboxFilter;
