import { Box } from "@mui/material";

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <Box
      component="footer"
      aria-label="footer"
      sx={{
        display: "flex",
        alignItems: "center",
        fontSize: "small",
        justifyContent: "center",
        color: "gray",
        p: "20px 0",
        width: "100%",
      }}
    >
      &copy;{year} PA Logic Solutions, LLC
    </Box>
  );
};

export default Footer;
