import { useConfirm } from "material-ui-confirm";
import { Box, IconButton, Tooltip } from "@mui/material";
import BackspaceOutlinedIcon from "@mui/icons-material/BackspaceOutlined";

const RemoveButton = ({
  id,
  item,
  onRemoveClick,
  disabled,
}: {
  id: number;
  item: string;
  onRemoveClick: Function;
  disabled: boolean;
}) => {
  const confirm = useConfirm();

  const remove = () => {
    confirm({
      title: `Remove ${item}?`,
      confirmationButtonProps: { color: "error" },
      confirmationText: "Remove",
      description: `This action cannot be undone.`,
    })
      .then(() => onRemoveClick(id))
      .catch(() => {});
  };

  return (
    <Tooltip arrow placement="right" title="Remove">
      <Box>
        <IconButton
          aria-label="Remove File"
          size="small"
          disabled={disabled}
          onClick={remove}
        >
          <BackspaceOutlinedIcon fontSize="small" />
        </IconButton>
      </Box>
    </Tooltip>
  );
};

export default RemoveButton;
