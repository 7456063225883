import { CSSProperties, useState } from "react";
import { TransitionState } from "../enums/TransitionState";

const FadeInOut = ({
  show,
  duration,
  children,
  style,
}: {
  show: boolean;
  duration: number;
  children: JSX.Element;
  style?: CSSProperties;
}) => {
  const [transitionState, setTransitionState] = useState(
    TransitionState.EXITED
  );

  const updateTransitionState = (newTransitionState: string | null) => {
    if (newTransitionState !== null) {
      if (newTransitionState === TransitionState.ENTERING) {
        performEnter();
      } else {
        performExit();
      }
    }
  };

  const performEnter = () => {
    setTransitionState(TransitionState.ENTERING);
    setTimeout(() => setTransitionState(TransitionState.ENTERED), 0);
  };

  const performExit = () => {
    setTransitionState(TransitionState.EXITING);
    setTimeout(() => setTransitionState(TransitionState.EXITED), duration);
  };

  let newTransitionState = null;

  if (show) {
    if (
      transitionState !== TransitionState.ENTERING &&
      transitionState !== TransitionState.ENTERED
    ) {
      newTransitionState = TransitionState.ENTERING;
    }
  } else if (
    transitionState === TransitionState.ENTERING ||
    transitionState === TransitionState.ENTERED
  ) {
    newTransitionState = TransitionState.EXITING;
  }

  updateTransitionState(newTransitionState);

  if (transitionState === TransitionState.EXITED) {
    return null;
  }

  return (
    <div
      style={{
        ...style,
        transition: `opacity ${duration}ms ease-in-out`,
        opacity: transitionState === TransitionState.ENTERED ? 1 : 0,
      }}
    >
      {children}
    </div>
  );
};

export default FadeInOut;
