import { forwardRef } from "react";
import { SnackbarContent, CustomContentProps } from "notistack";
import { Alert, AlertColor, AlertTitle } from "@mui/material";
import SnackbarCloseButton from "./SnackbarCloseButton";

interface MaterialSnackbarProps extends CustomContentProps {
  label?: string;
}

const MaterialSnackbar = forwardRef<HTMLDivElement, MaterialSnackbarProps>(
  ({ id, ...props }, ref) => {
    const variant = props.variant.toLowerCase().replace("material", "");

    return (
      <SnackbarContent ref={ref}>
        <Alert
          aria-label={
            props.label
              ? props.label
              : props.message
              ? props.message.toString()
              : ""
          }
          action={<SnackbarCloseButton snackbarId={id} />}
          icon={props.iconVariant[variant]}
          severity={variant as AlertColor}
          sx={{
            p: props.style.padding ? props.style.padding : "6px 16px",
            alignItems: "center",
            "& .MuiAlert-action": {
              pt: 0,
              pl: 0.5,
            },
          }}
        >
          <AlertTitle
            sx={{
              fontSize: "14px",
              m: 0,
            }}
          >
            {props.message}
          </AlertTitle>
        </Alert>
      </SnackbarContent>
    );
  }
);

MaterialSnackbar.displayName = "Material Snackbar";

export default MaterialSnackbar;
