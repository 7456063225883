import FileSaver from "file-saver";
import { Dispatch } from "react";
import {
  FileDetailsAction,
  UPDATE_FILE_DETAIL_STATUS,
  UPDATE_FILE_DETAIL_ERRORS,
} from "../actions/fileDetailsAction";
import { Error } from "../types/Error";
import { ValidationError } from "../types/ValidationError";
import { FileDetails } from "../types/FileDetails";
import { Status } from "../enums/Status";

export const createFile = (file: Blob, name: string, fileType: string) =>
  new File([file], name, { type: fileType });

export const saveFile = (file: Blob, name: string) => FileSaver.saveAs(file, name);

export const updateFile = (
  fileDetailsDispatch: Dispatch<FileDetailsAction>,
  fileDetails: FileDetails,
  status: Status,
  errors: {
    server: Error[];
    validation: ValidationError[];
  }
) => {
  fileDetailsDispatch({
    type: UPDATE_FILE_DETAIL_STATUS,
    id: fileDetails.id,
    status: status,
  });

  fileDetailsDispatch({
    type: UPDATE_FILE_DETAIL_ERRORS,
    id: fileDetails.id,
    errors: errors,
  });
};
