import {
  ADD_FILE_DETAIL,
  FileDetailsAction,
  REMOVE_FILE_DETAIL,
  RESET,
  UPDATE_FILE_DETAIL_ERRORS,
  UPDATE_FILE_DETAIL_STATUS,
  UPDATE_FILE_DETAIL_RESPONSE,
} from "../actions/fileDetailsAction";
import { FileDetails } from "../types/FileDetails";

const fileDetailsReducer = (
  fileDetails: FileDetails[],
  action: FileDetailsAction
): FileDetails[] => {
  switch (action.type) {
    case ADD_FILE_DETAIL:
      return [...fileDetails, action.fileDetail].map((f, i) => {
        return { ...f, id: i + 1 };
      });
    case UPDATE_FILE_DETAIL_STATUS: {
      const element = fileDetails.find((f) => f.id === action.id);

      if (element === undefined) console.error(`No file with id: ${action.id}.`);

      return fileDetails.map((f) => {
        if (f.id === action.id && element) {
          return { ...element, status: action.status };
        } else {
          return f;
        }
      });
    }
    case UPDATE_FILE_DETAIL_ERRORS: {
      const element = fileDetails.find((f) => f.id === action.id);

      if (element === undefined) console.error(`No file with id: ${action.id}.`);

      return fileDetails.map((f) => {
        if (f.id === action.id && element) {
          return { ...element, errors: action.errors };
        } else {
          return f;
        }
      });
    }
    case UPDATE_FILE_DETAIL_RESPONSE: {
      const element = fileDetails.find((f) => f.id === action.id);

      if (element === undefined) console.error(`No file with id: ${action.id}.`);

      return fileDetails.map((f) => {
        if (f.id === action.id && element) {
          return { ...element, response: action.file };
        } else {
          return f;
        }
      });
    }
    case REMOVE_FILE_DETAIL: {
      const id = fileDetails.findIndex((f) => f.id === action.id);

      if (id > -1) fileDetails.splice(id, 1);

      return [...fileDetails];
    }
    case RESET:
      return [];
  }
};

export default fileDetailsReducer;
