import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { SnackbarKey, useSnackbar } from "notistack";

const SnackbarCloseButton = ({
  snackbarId,
  color,
}: {
  snackbarId: SnackbarKey;
  color?: string;
}) => {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton size="small" onClick={() => closeSnackbar(snackbarId)}>
      <CloseIcon fontSize="small" htmlColor={color ? color : "inherit"} />
    </IconButton>
  );
};

export default SnackbarCloseButton;
