import { Status } from "../enums/Status";
import { Error } from "../types/Error";
import { ValidationError } from "../types/ValidationError";
import { FileDetails } from "../types/FileDetails";

export const RESET = "RESET";
export const ADD_FILE_DETAIL = "ADD_FILE_DETAIL";
export const UPDATE_FILE_DETAIL_STATUS = "UPDATE_FILE_DETAIL_STATUS";
export const UPDATE_FILE_DETAIL_ERRORS = "UPDATE_FILE_DETAIL_ERRORS";
export const UPDATE_FILE_DETAIL_RESPONSE = "UPDATE_FILE_DETAIL_RESPONSE";
export const REMOVE_FILE_DETAIL = "REMOVE_FILE_DETAIL";

export type FileDetailsAction =
  | { type: typeof RESET }
  | { type: typeof ADD_FILE_DETAIL; fileDetail: FileDetails }
  | { type: typeof UPDATE_FILE_DETAIL_STATUS; id: number; status: Status }
  | {
      type: typeof UPDATE_FILE_DETAIL_ERRORS;
      id: number;
      errors: { server: Error[]; validation: ValidationError[] };
    }
  | { type: typeof UPDATE_FILE_DETAIL_RESPONSE; id: number; file: File | undefined }
  | { type: typeof REMOVE_FILE_DETAIL; id: number };
